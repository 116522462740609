import { graphql, Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { Mdx } from "../../types/article"
import { rhythm, scale } from "../../utils/typography"
import { ArticleDate } from "../ArticleDate"
import { Tags } from "../Tags"

export type ArticleListItemData = Pick<
  Mdx<"title" | "published" | "updated" | "tags">,
  "fields" | "frontmatter" | "excerpt"
>

type Props = {
  className?: string
} & ArticleListItemData

const ArticleListItemInner: React.FC<Props> = ({
  className,
  fields,
  frontmatter,
  excerpt,
}) => {
  const title = frontmatter.title || fields.slug
  return (
    <article className={`${className} mb-5`}>
      <header className={`mb-2`}>
        <h3 className={`mb-1`}>
          <Link to={fields.slug} className={`no-underline`}>{title}</Link>
        </h3>
        <div className={`flex flex-row flex-wrap align-center`}>
          <small className={``}>
            <ArticleDate {...frontmatter} />
          </small>
          {frontmatter.tags ? (
            <div className={`ml-4`}>
              <Tags tags={frontmatter.tags} />
            </div>
          ) : null}
        </div>
      </header>
      <section>{excerpt}</section>
      <nav>
        <Link to={fields.slug} className={`text-sm`}>全文を見る</Link>
      </nav>
    </article>
  )
}

export const ArticleListItem = styled(ArticleListItemInner)`
  & > header > div {
    color: var(--fg-demisub-color);
  }
  & > nav a {
    color: var(--fg-sub-color);
  }
`

export const query = graphql`
  fragment ArticleInList on Mdx {
    excerpt
    fields {
      slug
    }
    frontmatter {
      published
      updated
      title
      tags
    }
  }
`
